import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";

import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModReferenceList from "../../../components/Chronology/Modules/ModReferenceList";
import ReferenceRow from "../../../components/Chronology/Modules/ReferenceRow";
import ModTwoCols from "../../../components/Chronology/Modules/ModTwoCols";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";
import ModCovidHighlight from "../../../components/Chronology/Modules/ModCovidHighlight";

export const frontmatter = {
  title: "Día 03",
  week: "Semana 1",
  month: "Mar",
  day: "16",
  monthNumber: "03",
  date: "2020-03-16",
  path: "/cronologia/semana-01#dia-16-mar",
};

const Day03 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
        <ModCovidHighlight>
          Kosovo declara su primer caso confirmado.
        </ModCovidHighlight>
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, el balance del día se salda con el
          registro de 2.002 nuevos positivos y 152 personas fallecidas.
          Permanecen hospitalizadas 2.471 personas, 860 más que el día anterior,
          y 11 reciben el alta. El número de casos ingresados en la UCI asciende
          a 276, lo que supone un aumento de 79 personas. Respecto al número de
          casos totales confirmados, la tasa de mortalidad es del 3,7 % y la
          tasa de recuperados del 6,9 %.
        </ModText>
        <ModTwoCols
          src="/images/svg/16_mar_estado_alarma.svg"
          alt="Españoles en sus hogares"
          small={true}
          inverted={false}
        >
          El Consejo de Ministros decretó estado de alarma el pasado sábado por
          la pandemia del coronavirus Covid-19. Se han aprobado una batería de
          medidas para contener la enfermedad, y la principal, es el
          confinamiento de todos los españoles en sus casas.
        </ModTwoCols>
        <ModText>
          Durante los 15 días de vigencia del estado de alarma, los ciudadanos
          únicamente podrán circular por las vías de uso público para adquirir
          alimentos, productos farmacéuticos y de primera necesidad; acudir a
          centros sanitarios; desplazarse a su lugar de trabajo o prestar
          servicios laborales; regresar a su domicilio habitual; asistir a
          mayores, menores, dependientes o discapacitados; hacer gestiones en
          entidades financieras; o salvo causas de fuerza mayor o justificadas.
        </ModText>
        <ModText>
          El movimiento de vehículos particulares estará sujeto a las mismas
          reglas aunque también podrán desplazarse para acudir a estaciones de
          servicio o repostaje de combustibles.
        </ModText>
        <ModText>
          En cuanto al ámbito educativo, todas las comunidades autónomas cierran
          todos sus centros educativos para los próximos 15 días.
        </ModText>
        <ModImage
          src="/images/svg/16_mar_control_pasaportes.svg"
          alt="control de pasajeros en fronteras"
        />
        <ModText>
          Además, a partir de esta medianoche, sólo se permitirá la entrada en
          territorio nacional por vía terrestre a ciudadanos españoles; personas
          residentes en España; trabajadores transfronterizos y aquellos que
          acrediten, documentalmente, causas de fuerza mayor o situación de
          necesidad. La resolución señala que esta restricción no se aplicará al
          transporte de mercancías ni tampoco afecta al personal extranjero
          acreditado.
        </ModText>
        <ModReferenceList title="Guías y documentos publicados">
          <ReferenceRow
            link="https://www.mscbs.gob.es/campannas/campanas20/coronavirus.htm"
            name='Campaña institucional "Este virus lo paramos unidos"'
          />
          <ReferenceRow
            link="https://www.mscbs.gob.es/gabinete/notasPrensa.do?metodo=detalle&id=4812"
            name="Medidas establecidas por el Ministerio de Sanidad"
          />
        </ModReferenceList>
      </ContentRight>
    </LayoutDay>
  );
};

export default Day03;
