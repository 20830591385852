import React, { useEffect, useState, useRef } from 'react';
import { Link } from "gatsby";
import { useStaticQuery, graphql } from "gatsby"
import { ArrowTop, ArrowBottom } from '../IconSystem'
import { Box, Flex, Text, MaxWidth } from '@sqymagma/elements' 

const LayoutWeek =  ({ children, path }) => {


    const navData = useStaticQuery(graphql`
    query {
        positiveWeeks: allSitePage(
            filter: { id: { regex: "/semana-[0-9]+/$/" } }
            sort: { fields: path, order: DESC }
        ) {
            edges {
            node {
                path
            }
            }
        }
        negativeWeeks: allSitePage(
            filter: { id: { regex: "/semana-previa-[0-9]/$/" } }
            sort: { fields: path, order: ASC }
        ) {
            edges {
            node {
                path
            }
            }
        }
    } 
    `)

    const allWeeksArr = navData.positiveWeeks.edges.concat(navData.negativeWeeks.edges)
    const allWeeksNodes = allWeeksArr;
    const currentWeekNode = allWeeksNodes.find(el => el.node.path === path)
    const currentIndex = allWeeksNodes.indexOf(currentWeekNode)
    const prevWeekNode = allWeeksNodes[currentIndex - 1]
    const nextWeekNode = allWeeksNodes[currentIndex + 1]


    const prevBtnRef = useRef();
    const weekSentinel = useRef();

    return (
            <>
            <Box ref={prevBtnRef} minHeight=".05rem" position="relative">
                {prevWeekNode && 
                    <GoPrevWeek 
                    link={prevWeekNode.node.path}
                    weekSentinel={weekSentinel} 
                    prevBtnRef={prevBtnRef}/>
                }
            </Box>

            <Box position="relative">
                <Box position="absolute" top="100vh" ref={weekSentinel}></Box>
                {children}
            </Box>

            {nextWeekNode &&  <GoNextWeek link={nextWeekNode.node.path}/>}
            </>
    )
}

export default LayoutWeek;


const GoPrevWeek =  ({ link, prevBtnRef, weekSentinel }) => {

    const [ hasScrolled, setHasScrolled ] = useState(false)
    const [ isBtnInView, setIsBtnInView ] = useState(false)

    useEffect(() => {
        const onChange = (entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                  if (entry.target === weekSentinel.current) {
                    setHasScrolled(true)
                    observer.unobserve(entry.target)
                  } 
                  if (entry.target === prevBtnRef.current) {
                    hasScrolled && setIsBtnInView(true)
                    observer.unobserve(entry.target)
                  }
                }
              })
        }
        const observer = new IntersectionObserver(onChange, { threshold: 0})
        const elsToObserve = [weekSentinel, prevBtnRef]
        elsToObserve.forEach(el => el && observer.observe(el.current))
    }, [hasScrolled, prevBtnRef, weekSentinel])


    return (
        <Link to={link}>
        <Flex 
            height={(hasScrolled && isBtnInView) ? "51px" : "0"}
            width="100vw" bg="ui06"
            position={(hasScrolled && isBtnInView) ? "relative" : "absolute"}
            css={` {
                transition: all ease-out 0.35s;
                transition-delay: .4s;

                &:hover {
                    span {
                        opacity: 1;
                    }
                }
            }`}
            overflow="visible"
        >
            <MaxWidth>
                <Flex alignItems="center" justifyContent="space-between">
                    <Box width="80px"></Box>
                    <ArrowTop 
                    fill="ui01" width="28px" height="28px" padding="6px" 
                    css={`{
                            display: flex;
                            align-items: center;
                            justify-content: center
                            transform: scale(1);
                            border-radius: 50%;
                            animation: pulse 2s infinite;
                            background: rgba(255,255,255,.075);
                            border: 1px solid rgba(255,255,255,.1)
                            border-radius: 50%;
                    }`}/>
                    
                    <Text color="text06" textStyle="label-large" opacity="0"
                        css={`{transition: opacity ease-in .3s}`}
                    >
                            Cargar más</Text>
                </Flex>
            </MaxWidth>
        </Flex>
        </Link>
    )
}


const GoNextWeek =  ({ link }) => {

    return (
        <Link to={link}>
        <Flex 
            height="51px" width="100vw" bg="ui06"
            position="relative"
            css={` {
                transition: all ease-out 0.35s;
                transition-delay: .4s;

                &:hover {
                    span {
                        opacity: 1;
                    }
                }
            }`}
            overflow="visible"
        >
            <MaxWidth>
                <Flex alignItems="center" justifyContent="space-between">
                    <Box width="80px"></Box>
                    <ArrowBottom
                    fill="ui01" width="28px" height="28px" padding="6px" 
                    css={`{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            transform: scale(1);
                            border-radius: 50%;
                            animation: pulse 2s infinite;
                            background: rgba(255,255,255,.075);
                            border: 1px solid rgba(255,255,255,.1)
                            border-radius: 50%;
                    }`}/>
                    
                    <Text color="text06" textStyle="label-large" opacity="0"
                        css={`{transition: opacity ease-in .3s}`}
                    >
                            Cargar más</Text>
                </Flex>
            </MaxWidth>
        </Flex>
        </Link>
    )
}