import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModTwoCols from "../../../components/Chronology/Modules/ModTwoCols";

import ModDatafactCountry from "../../../components/Chronology/Modules/ModDatafactCountry";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";

export const frontmatter = {
  title: "Día 06",
  week: "Semana 1",
  month: "Mar",
  day: "19",
  monthNumber: "03",
  date: "2020-03-19",
  path: "/cronologia/semana-01#dia-19-mar",
};

const Day06 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
        <ModText>
          <strong>Italia</strong> se ha convertido hoy en el país que más
          muertes ha reportado a causa del nuevo coronavirus, con 3.405 personas
          fallecidas. Con este número, se convierte en el primer país del mundo
          en número de víctimas mortales.
        </ModText>
        <ModDatafactCountry
          fecha={frontmatter.date}
          country="it"
          data="casosFallecidos"
        />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, el balance del día se salda con el
          registro de 3.431 nuevos positivos y 169 personas fallecidas.
          Permanecen hospitalizadas 5.475 personas, 1.474 más que el día
          anterior, y 50 reciben el alta. El número de casos ingresados en la
          UCI asciende a 939, lo que supone un aumento de 165 personas. Respecto
          al número de casos totales confirmados, la tasa de mortalidad es del
          4,5 % y la tasa de recuperados del 7,1 %.
        </ModText>
        <ModText>
          El <strong>Boletín Oficial del Estado</strong> ha publicado la Orden
          SND/257/2020 por la que se declara la suspensión de apertura al
          público de establecimientos de alojamiento turístico. Estos
          establecimientos deberán ir cerrando, progresivamente, antes del día
          26.
        </ModText>
        <ModImage
          src="https://coronavirus.secuoyas.com/svg/img-transport.svg"
          alt="medios de transporte públicos"
        />
        <ModText>
          El{" "}
          <strong>Ministerio de Transportes, Movilidad y Agenda Urbana</strong>{" "}
          ha dictado sendas órdenes ministeriales por la que se establecen
          restricciones en el transporte entre la península y las Islas
          Baleares, así como entre la península y las Islas Canarias en orden a
          la protección de personas, bienes y lugares.
        </ModText>
        <ModTwoCols
          src="https://coronavirus.secuoyas.com/svg/img-care.svg"
          alt="medicos conversando"
          small={true}
          inverted={false}
        >
          El <strong>Ministerio de Sanidad</strong> dota de medios humanos y
          profesionales al SNS para combatir y tratar de contener el COVID-19.
          La ampliación del equipo humano sanitario en más de 50.000
          profesionales y la distribución de 210 millones de euros que ayuden a
          los gobiernos autonómicos a contar con una respuesta rápida para las
          acciones más urgentes, son algunas de las propuestas aprobadas.
        </ModTwoCols>
        <ModText>
          El <strong>Ministerio de Asuntos Exteriores, UE y Cooperación</strong>{" "}
          continúa con las gestiones para garantizar la asistencia de todos los
          españoles que se encuentran en el exterior y precisan de ayuda para
          retornar a España.
        </ModText>
      </ContentRight>
    </LayoutDay>
  );
};

export default Day06;
