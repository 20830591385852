import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";

import InlineLink from "../../../components/Chronology/Modules/InlineLink";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";

export const frontmatter = {
  title: "Día 02",
  week: "Semana 1",
  month: "Mar",
  day: "15",
  monthNumber: "03",
  date: "2020-03-15",
  path: "/cronologia/semana-01#dia-15-mar",
};

const Day02 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, el balance del día se salda con el
          registro de 2.002 nuevos positivos y 152 personas fallecidas.
          Permanecen hospitalizadas 2.471 personas, 860 más que el día anterior,
          y 11 reciben el alta. El número de casos ingresados en la UCI asciende
          a 276, lo que supone un aumento de 79 personas. Respecto al número de
          casos totales confirmados, la tasa de mortalidad es del 3,7 % y la
          tasa de recuperados del 6,9 %.
        </ModText>
        <ModText>
          El Gobierno lanza la campaña institucional{" "}
          <InlineLink link="https://www.mscbs.gob.es/campannas/campanas20/coronavirus.htm">
            “Este virus lo paramos unidos”
          </InlineLink>
          para incentivar las medidas de distanciamiento social frente al
          coronavirus. La campaña se ha publicado hoy en las principales
          cabeceras de prensa escrita del país, y se difundirá también en radio,
          televisión, prensa e internet durante los próximos días.
        </ModText>
        <ModText>
          El Ministerio de Sanidad establece{" "}
          <InlineLink link="https://www.mscbs.gob.es/gabinete/notasPrensa.do?metodo=detalle&id=4812">
            medidas
          </InlineLink>
          para el refuerzo de personal sanitario y el suministro de material.
        </ModText>
      </ContentRight>
    </LayoutDay>
  );
};

export default Day02;
