import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModTwoCols from "../../../components/Chronology/Modules/ModTwoCols";
import ModText from "../../../components/Chronology/Modules/ModText";
import InlineLink from "../../../components/Chronology/Modules/InlineLink";
import ModReferenceList from "../../../components/Chronology/Modules/ModReferenceList";
import ReferenceRow from "../../../components/Chronology/Modules/ReferenceRow";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";

export const frontmatter = {
  title: "Día 01",
  week: "Semana 1",
  month: "Mar",
  day: "14",
  monthNumber: "03",
  date: "2020-03-14",
  path: "/cronologia/semana-01#dia-14-mar",
};

const Day01 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, el balance del día se salda con el
          registro de 1.546 nuevos positivos y 18 personas fallecidas.
          Permanecen hospitalizadas 1.611 personas, 232 más que el día anterior,
          y 329 reciben el alta. El número de casos ingresados en la UCI
          asciende a 197, lo que supone un aumento de 4 personas. Respecto al
          número de casos totales confirmados, la tasa de mortalidad es del
          2,4 % y la tasa de recuperados del 9,1 %.
        </ModText>
        <ModText>
          <strong>El Gobierno ha decretado hoy</strong>{" "}
          <InlineLink link="https://www.lamoncloa.gob.es/consejodeministros/resumenes/Paginas/2020/14032020_alarma.aspx">
            el estado de alarma
          </InlineLink>
          para hacer frente a la expansión de coronavirus COVID-19
        </ModText>
        <ModTwoCols
          src="https://coronavirus.secuoyas.com/svg/14_mar_compra.svg"
          alt="compras alimentos"
          small={true}
          inverted={false}
        >
          Durante el periodo establecido (próximos 15 días naturales),{" "}
          <strong>
            la circulación deberá realizarse individualmente y se limitará a
            actividades de primera necesidad
          </strong>
          , tales como compras de alimentos o medicamentos, asistencia a centros
          sanitarios o entidades financieras, retorno a la residencia habitual o
          asistencia a personas vulnerables. También se exceptúan los
          desplazamientos al lugar de trabajo.
        </ModTwoCols>
        <ModTwoCols
          src="https://coronavirus.secuoyas.com/svg/14_mar_transporte.svg"
          alt="transporte público"
          small={true}
          inverted={false}
        >
          El{" "}
          <strong>
            transporte de viajeros, tanto público como privado, reducirá su
            oferta al menos a la mitad
          </strong>{" "}
          durante los próximos 15 días, salvo los servicios ferroviarios de
          cercanías, que la mantendrán. Los vehículos de transporte de pasajeros
          seguirán una rutina de limpieza diaria basada en las recomendaciones
          del Ministerio de Sanidad.
        </ModTwoCols>
        <ModTwoCols
          src="https://coronavirus.secuoyas.com/svg/14_mar_farmacia.svg"
          alt="productos de salud"
          small={true}
          inverted={false}
        >
          Se<strong> garantizará el abastecimiento</strong> de los productos
          necesarios para la <strong>salud pública</strong> y el suministro de{" "}
          <strong>alimentos</strong>.
        </ModTwoCols>
        <ModTwoCols
          src="https://coronavirus.secuoyas.com/svg/14_mar_servicio_domicilio.svg"
          alt="servicio a domicilio"
          small={true}
          inverted={false}
        >
          Los comercios de{" "}
          <strong>
            hostelería podrán prestar exclusivamente servicio a domicilio
          </strong>
          , mientras que otro tipo de locales de espectáculos, o donde se
          realicen actividades culturales, artísticas, deportivas, y similares
          deberán suspender su apertura estos días.
        </ModTwoCols>
        <ModTwoCols
          src="https://coronavirus.secuoyas.com/svg/14_mar_clases.svg"
          alt="actividad escolar"
          small={true}
          inverted={false}
        >
          Igualmente, se{" "}
          <strong>suspende la actividad escolar presencial</strong> en todos los
          niveles educativos en favor de actividades educativas online durante
          este periodo.
        </ModTwoCols>
        <ModReferenceList title="Guías y documentos publicados">
          <ReferenceRow
            link="https://www.lamoncloa.gob.es/consejodeministros/resumenes/Paginas/2020/14032020_alarma.aspx"
            name="El Gobierno decreta el estado de alarma"
          />
        </ModReferenceList>
      </ContentRight>
    </LayoutDay>
  );
};

export default Day01;
