import React from "react";
import LayoutWeek from "../../../components/Chronology/LayoutWeek";
import Day01 from "./dia-14-mar";
import Day02 from "./dia-15-mar";
import Day03 from "./dia-16-mar";
import Day04 from "./dia-17-mar";
import Day05 from "./dia-18-mar";
import Day06 from "./dia-19-mar";
import Day07 from "./dia-20-mar";
import SecWeekly, {
  SecWeeklyColumn,
} from "../../../components/Chronology/Modules/SecWeekly";
import ModMegaBanner from "../../../components/Chronology/Modules/ModMegaBanner";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";
import { StrongGold } from "../../../components/Textstyles/Styles";

const Week = (props) => {
  return (
    <>
      <LayoutWeek {...props}>
        <SecWeekly
          period="14 Marzo - 21 Marzo"
          week="Semana 1 de confinamiento"
        >
          <SecWeeklyColumn>
            El Gobierno decreta el <strong>estado de alarma</strong> para hacer
            frente a la crisis provocada por COVID-19
            <ModDottedLine />
            <strong>“Este virus lo paramos unidos”, </strong>campaña
            institucional para incentivar las medidas de distanciamiento social
            <ModDottedLine />
            España alcanza la cifra de <strong>
              20.000 casos positivos
            </strong>{" "}
            en COVID-19
          </SecWeeklyColumn>
          <SecWeeklyColumn>
            <strong>Confinamiento masivo</strong> por la expansión de
            coronavirus
            <ModDottedLine />
            La <strong>circulación</strong> deberá realizarse{" "}
            <strong>individualmente</strong> y se limitará a actividades de
            primera necesidad
            <ModDottedLine />
            Se aprueban <strong>medidas urgentes extraordinarias</strong> para
            hacer frente al <strong>impacto económico y social</strong>
          </SecWeeklyColumn>
        </SecWeekly>
        <Day07 {...props} />
        <Day06 {...props} />
        <Day05 {...props} />
        <Day04 {...props} />
        <ModMegaBanner>
          <strong>Primer día laborable </strong> de{" "}
          <StrongGold>confinamiento masivo </StrongGold> por la expansión de
          coronavirus
        </ModMegaBanner>
        <Day03 {...props} />
        <Day02 {...props} />
        <Day01 {...props} />
        <ModMegaBanner bg={"brand01"}>
          El Gobierno de España declara el{" "}
          <StrongGold>estado de alarma</StrongGold> por crisis sanitaria debido
          al bróte epidémico de COVID-19
        </ModMegaBanner>
      </LayoutWeek>
    </>
  );
};

export default Week;
