import React from "react";
import { Flex, Box, MaxWidth, Text, Columns } from "@sqymagma/elements";

export default ({ week, period, children }) => {
  return (
    <Flex width={1} bg="brand05" py="xl">
      <MaxWidth my="l">
        <Box mx="auto">
          <Text as="h2" textStyle="mega" color="brand04">
            Resumen {week}
          </Text>
          <Text as="h3" textStyle="xxl">
            [{period}]
          </Text>
          <Columns cols={[1, null, null, 2]} hs="xl" mt="l" alignItems="center">
            {children}
          </Columns>
        </Box>
      </MaxWidth>
    </Flex>
  );
};

export const SecWeeklyColumn = ({ side, children }) => (
  <Box>
    <Text
      as="p"
      textStyle="xxl-banner"
      css={`{
           & strong {
               color white; font-weight: 800
            }
           & hr {
            border-width: 0 0 1px 0;
            border-style: dotted;
            border-color: #ffffff;
            margin: 0.25em 0;
           }

            img {
                width: 80%;
                max-width: 400px;
                margin: 0 auto;
            }
           
        }`}
    >
      {children}
    </Text>
  </Box>
);
