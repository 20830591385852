import React, { useState, useEffect } from "react";
import { Box, Flex, Text, Inline } from "@sqymagma/elements";
import { formatNumberDot } from "../../../utils/formatNumber";
import DataFactLoader from "../../Loaders/DataFactLoader";
import IconSystem from "../../IconSystem"
import usePascalCase from "../../../utils/usePascalCase"

export default ({
  fecha,
  country,
  data,
  customText = null,
  customNumber = null,
}) => {
  const microCopy = {
    casosConfirmados: "Casos confirmados",
    casosConfirmadosDiario: "Casos confirmados durante el día",
    casosFallecidos: "Personas fallecidas",
    casosFallecidosDiario: "Personas fallecidas durante el día",
  };

  const [datafact, setDatafact] = useState(null);
  useEffect(() => {
    fetch(`https://covid19.secuoyas.io/api/v1/mod/world?fecha=${fecha}`)
      .then((response) => response.json()) // parse JSON from request
      .then((resultData) => {
        const selectedCountry =
          resultData &&
          resultData.paises.filter(
            (el) => el.codigoIsoLugar.toLowerCase() === country.toLowerCase()
          );
        setDatafact(
          selectedCountry &&
            selectedCountry.map((el) => {
              return {
                ...el,
                nombreLugar: el.nombreLugar.split("_").join(" "),
                datafact: el[data],
              };
            })[0]
        );
      })
      .catch((err) => console.log("Error getting data from API"));
  }, [fecha, country, data]);

  
  const isoCode = usePascalCase(country)

  return datafact && isoCode ? (
    <Box my="m" width={1} maxWidth="400px">
      <Inline justifyContent="space-between">
        <Flex alignItems="center" width={[1/2, 1/3]} pr={["s", 0]}>
          <Box width={1/2} mr="s" maxWidth={["3rem","4rem"]}  minWidth="2.5rem">
              <IconSystem name={`map${isoCode}`}/>
          </Box>
          <Flex flexDirection="column">
              <Box maxWidth="1.5rem" display="inline-grid" mb="xs">
                  <IconSystem name={`flag${isoCode}`}/>
              </Box>
            <Text as="p" textStyle="l">
              {datafact && datafact.nombreLugar}
            </Text>
          </Flex>
        </Flex>
        <Inline width={[1/2, 2/3]} justifyContent="flex-end" alignItems={['flex-start', 'center']} flexDirection={["column", "row"]}>
          {customNumber ? (
            <Text fontWeight="800" fontSize="40px" color="brand02" mr="s">
              {formatNumberDot(customNumber)}
            </Text>
          ) : (
            datafact && (
              <Text fontWeight="800" fontSize="40px" color="brand02" lineHeight="1.2" mr={["xs", "s"]}>
                {datafact.datafact && formatNumberDot(datafact.datafact)}
              </Text>
            )
          )}
          {customText ? (
            <Text textStyle="s" color="brand05" width={[1, "35%"]}>
              {customText}
            </Text>
          ) : microCopy[data] ? (
            <Text textStyle="s" color="brand05" width={[1, "35%"]}>
              {microCopy[data]}
            </Text>
          ) : (
            "Error, revisa la prop data"
          )}
        </Inline>
      </Inline>
    </Box>
  ) : (
    <Box py="m" my="s"  width={1}>
      <DataFactLoader />
    </Box>
  );
};
