import { useState, useEffect } from 'react';

export default (str) => {
  const [pascalCase, setPascalCase] = useState(null);

  str = str.toLowerCase();
  
  useEffect(_ => {
      let strArr = str.split("-")
      strArr = strArr.map(particle => {
        return particle.charAt(0).toUpperCase() + particle.slice(1)
      })

      setPascalCase(strArr.join(""))
  },[str])

  return pascalCase;
}