import React from "react";
import { Box, Flex } from "@sqymagma/elements";
import { Paragraph } from "../../Textstyles/Styles";

export default ({ children, src, alt = "", small, inverted }) => {
  return (
    <Flex width={1} my="m" flexDirection={inverted ? "row-reverse" : "row"}>
      <Box width={small ? ["100%", "23.5%"] : ["100%", "50%"]} mr={["0", "6%"]}>
        <img src={src} alt={alt} />
      </Box>
      <Paragraph
        width={small ? ["100%", "66%"] : ["100%", "50%"]}
        mr={inverted && ["0", "6%"]}
      >
        {children}
      </Paragraph>
    </Flex>
  );
};
