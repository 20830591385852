    import React from "react"
    import { Flex, Text, MaxWidth } from "@sqymagma/elements"
    import { theme } from "../../../utils/themeGet"


    export default ({ children, bg="brand05" }) => {
        const bgColorSet = ["brand01"]
        let textColor = bgColorSet.includes(bg) ? "text06" : "text01"
        let hoverTextColor = bgColorSet.includes(bg) ? "text05" : "text06"
    return (
        <Flex width={1} bg={bg} py={["l", "m","xl"]}>
        <MaxWidth my="l">
            <Text
                as="h2"
                textStyle="mega"
                css={`
                    strong {
                    font-weight: 900;
                    color: ${theme(`color.${hoverTextColor}`)};
                    }
                `}
                color={textColor}
            >
            {children}
            </Text>
        </MaxWidth>
        </Flex>
    )
    }


    export const StrongGold = ({children}) => (
    <Text 
        as="strong"  
        className="strong-gold" 
        css={`&.strong-gold {color: ${theme("color.brand04")}}`}>
        {children}
    </Text>
    )