import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModTwoCols from "../../../components/Chronology/Modules/ModTwoCols";

import ModCovidHighlight from "../../../components/Chronology/Modules/ModCovidHighlight";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";

export const frontmatter = {
  title: "Día 07",
  week: "Semana 1",
  month: "Mar",
  day: "20",
  monthNumber: "03",
  date: "2020-03-20",
  path: "/cronologia/semana-01#dia-20-mar",
};

const Day07 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
        <ModCovidHighlight>
          Gibraltar declara los primeros casos confirmados de COVID-19 .
        </ModCovidHighlight>
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, el balance del día se salda con el
          registro de 2.833 nuevos positivos y 235 personas fallecidas.
          Permanecen hospitalizadas 6.705 personas, 1.230 más que el día
          anterior, y 372 reciben el alta. El número de casos ingresados en la
          UCI asciende a 1.125, lo que supone un aumento de 186 personas.
          Respecto al número de casos totales confirmados, la tasa de mortalidad
          es del 5,0 % y la tasa de recuperados del 7,9 %.
        </ModText>
        <ModTwoCols
          src="https://coronavirus.secuoyas.com/svg/img-science.svg"
          alt="ciencia contra el sars-cov-2"
          small={true}
          inverted={false}
        >
          El <strong>Ministerio de Ciencia e Innovación</strong> ha subrayado
          hoy que la ciencia trabaja a contrarreloj para encontrar una solución
          a la emergencia sanitaria provocada por el SARS-COV-2.
        </ModTwoCols>
        <ModText>
          El <strong>Ministerio de Sanidad</strong> colabora asimismo con la OMS
          (Organización Mundial de la Salud) en un gran ensayo internacional,
          denominado solidarity, que cuenta con la participación de países de
          Europa, Asia, América y África, y cuyo objetivo es medir la
          efectividad de los tratamientos que se están utilizando actualmente.
        </ModText>
        <ModText>
          Además, también{" "}
          <strong>
            se han autorizado ensayos clínicos con una nueva molécula
          </strong>{" "}
          en los que participarán ocho hospitales españoles, con el objetivo de
          definir qué medicamentos nuevos tendrán aplicación en la lucha contra
          el COVID-19 así como cuáles de los ya autorizados pueden ser
          administrados siendo seguros y lograr resultados eficaces.
        </ModText>
        <ModTwoCols
          src="https://coronavirus.secuoyas.com/svg/20_mar_ministerio_justicia.svg"
          alt="medicos forenses"
          small={false}
          inverted={false}
        >
          El <strong>Ministerio de Justicia </strong>pone a disposición de las
          autoridades sanitarias los servicios de 765 médicos forenses y 180
          facultativos que no estén prestando servicios esenciales en la
          Administración de Justicia, para que puedan dar apoyo en la detección
          del Covid-19. Se trata de personal con formación especializada en la
          realización de pruebas PCR (las que se utilizan para la detección del
          virus) a tiempo real.
        </ModTwoCols>
      </ContentRight>
    </LayoutDay>
  );
};

export default Day07;
