import React from "react"
import ContentLoader from "react-content-loader" 

const DataFactLoader = () => (
  <ContentLoader 
    speed={2}
    style={{width:"100%"}}
    height={120}
    viewBox="0 0 560 120"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="96" y="66" rx="3" ry="3" width="88" height="6" /> 
    <rect x="96" y="80" rx="3" ry="3" width="52" height="6" /> 
    <rect x="96" y="42" rx="0" ry="0" width="17" height="11" /> 
    <rect x="400" y="47" rx="3" ry="3" width="88" height="6" /> 
    <rect x="400" y="61" rx="3" ry="3" width="52" height="6" /> 
    <rect x="251" y="43" rx="0" ry="0" width="130" height="32" /> 
    <circle cx="46" cy="64" r="39" />
  </ContentLoader>
)

export default DataFactLoader