import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModTwoCols from "../../../components/Chronology/Modules/ModTwoCols";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModAnimation from "../../../components/Chronology/Modules/ModAnimation";
import ModCovidHighlight from "../../../components/Chronology/Modules/ModCovidHighlight";

import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";

export const frontmatter = {
  title: "Día 05",
  week: "Semana 1",
  month: "Mar",
  day: "18",
  monthNumber: "03",
  date: "2020-03-18",
  path: "/cronologia/semana-01#dia-18-mar",
};

const Day05 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
        <ModCovidHighlight>
          <strong> Montenegro</strong> declara los dos primeros casos
          confirmados de COVID-19 .
        </ModCovidHighlight>
      </ContentLeft>

      <ContentRight>
        <ModText>
          "En <strong>España</strong>, el balance del día se salda con el
          registro de 2.538 nuevos positivos y 107 personas fallecidas.
          Permanecen hospitalizadas 4.001 personas, 91 más que el día anterior,
          y 74 reciben el alta. El número de casos ingresados en la UCI asciende
          a 774, lo que supone un aumento de 211 personas." Respecto al número
          de casos totales confirmados, la tasa de mortalidad es del 4,4 % y la
          tasa de recuperados del 8,5 %.
        </ModText>
        <ModAnimation svg="/images/anim/18_mar_pruebas.svg" />
        <ModText>
          El Ministerio de Sanidad trabaja en un proyecto avanzado para realizar
          test rápidos de diagnóstico del COVID-19 que supondrá un gran avance
          para el diagnóstico precoz de la enfermedad.
        </ModText>
        <ModTwoCols
          src="https://coronavirus.secuoyas.com/svg/18_mar_test_casa.svg"
          alt="análisis seriológico"
          small={false}
          inverted={false}
        >
          El objetivo es que estos test se realicen a una población más amplia,
          tanto a colectivos vulnerables como a aquellas personas que
          permanezcan en régimen domiciliario que presenten sintomatología.
        </ModTwoCols>
      </ContentRight>
    </LayoutDay>
  );
};

export default Day05;
